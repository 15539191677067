import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VTextField,{attrs:{"label":"Buscar"}}),_vm._l((_vm.library),function(book){return _c(VRow,{key:book.id},[_c(VCol,[_c(VCard,[_c(VCardText,{staticClass:"title"},[_vm._v(" "+_vm._s(book.title)+" ")]),_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c(VImg,{attrs:{"src":book.cover,"max-width":"200","max-height":"300"}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"to":_vm.editLink(book)}},[_vm._v(" Editar ")])],1)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }