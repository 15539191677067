import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}}),_c(VCol,[_c(VRow,[_c(VCol,[_c(VBtn,{on:{"click":function($event){return _vm.gotoCover()}}},[_vm._v(" Cover ")])],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{on:{"click":function($event){return _vm.gotoPages()}}},[_vm._v(" Pages ")])],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"secondary","to":"/"}},[_vm._v(" Go Back Main Page ")])],1),_c(VCol,[_c(VBtn,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteBook()}}},[_vm._v(" Delete Book ")])],1)],1)],1),_c(VCol,{attrs:{"cols":"3"}})],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }