import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VContainer,_vm._l((_vm.notifications),function(notification){return _c(VRow,{key:notification.id},[_c(VCard,[_c(VCardText,[_vm._v(" "+_vm._s(notification.text)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(notification.sender.name)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(notification.target.title)+" ")]),_c(VCardActions,[_c(VBtn,[_vm._v(" Aceptar ")]),_c(VBtn,[_vm._v(" Denegar ")]),_c(VBtn,[_vm._v(" Abrir libro solicitado ")])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }