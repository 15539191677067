import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VTextField,{attrs:{"label":"Buscar"},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}}),_vm._l((_vm.library),function(book){return _c(VRow,{key:book.id},[_c(VCol,[_c(VCard,[_c(VCardText,{staticClass:"title"},[_vm._v(" "+_vm._s(book.title)+" ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VImg,{attrs:{"src":book.cover,"max-width":"200","max-height":"300"}})],1),_c(VCol,[_c('span',[_c('p',[_vm._v(" Likes: "+_vm._s(book.likes)+" ")]),_c('p',[_vm._v(" Reads: "+_vm._s(book.reads)+" ")]),_c('p',[_vm._v(" Active: "+_vm._s(book.active === 1? 'Activo' : 'No activo')+" ")])])])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"to":_vm.editLink(book),"outlined":"","color":"primary"}},[_vm._v(" Edit ")]),_c(VBtn,{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.deleteBook(book)}}},[_vm._v(" Delete ")])],1)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }