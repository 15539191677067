import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Titulo"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextarea,{attrs:{"label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c(VRow,[_c(VCol,[_c(VFileInput,{attrs:{"rules":_vm.rules,"accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera","label":"Imagen"},on:{"change":function($event){return _vm.handleFileUpload()}},model:{value:(_vm.fileModel),callback:function ($$v) {_vm.fileModel=$$v},expression:"fileModel"}}),_c(VLabel,[_vm._v(_vm._s(_vm.addedUrl))])],1)],1),_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c(VImg,{attrs:{"src":_vm.addedUrl,"max-width":"200"}})],1)],1),_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"label":"Genre","items":_vm.genres},model:{value:(_vm.selectedGenre),callback:function ($$v) {_vm.selectedGenre=$$v},expression:"selectedGenre"}})],1),_c(VCol,[_c(VSelect,{attrs:{"label":"Sub Genre","items":_vm.genreSubGenres},model:{value:(_vm.selectedSubGenre),callback:function ($$v) {_vm.selectedSubGenre=$$v},expression:"selectedSubGenre"}})],1)],1),_c(VRow,[_c(VCol,[_c(VCheckbox,{attrs:{"label":"Libro terminado. Seleccionar esta opcion para que aparezca en la libreria."},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1)],1),_c(VRow,[_c(VCol)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.gotoBack()}}},[_vm._v(" Volver ")])],1),_c(VCol,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addBook()}}},[_vm._v(" Guardar ")])],1)],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }