import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.pageHeaders,"items":_vm.pages,"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Paginas")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addPage()}}},[_vm._v(" Agreagar Pagina ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",attrs:{"color":"orange"},on:{"click":function($event){return _vm.gotoEditLink(item.id)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deletePage(item.id)}}},[_vm._v("mdi-delete")])]}}])})],1)],1),_c(VRow,[_c(VCol,[_c(VBadge,{attrs:{"color":"blue","bottom":""}},[_vm._v(" Initial Page ")])],1),_c(VCol,[_c(VBadge,{attrs:{"color":"yellow","bottom":""}},[_vm._v(" Mid Page ")])],1),_c(VCol,[_c(VBadge,{attrs:{"color":"red","bottom":""}},[_vm._v(" End Page ")])],1)],1),_c(VRow,[_c(VCol,[_c('span',[_c('p',[_vm._v(" If the color is dark, then the page is empty ")])])])],1),_c(VRow,[_c('div',{staticStyle:{"position":"relative","width":"100%"}},[_c('div',{staticStyle:{"border":"solid 1px black","width":"100%","height":"800px"},attrs:{"id":"myDiagramDiv"}}),_c('ul',{staticClass:"menu",attrs:{"id":"contextMenu"}},[_c('li',{staticClass:"menu-item",attrs:{"id":"edit"},on:{"click":function($event){return _vm.editBookPage()}}},[_vm._v("Edit")]),_c('li',{staticClass:"menu-item",attrs:{"id":"delete"},on:{"click":function($event){return _vm.deleteBookPage()}}},[_vm._v("Delete")])])])]),_c(VRow,[_c(VCol)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"secondary","to":("/book/" + (this.$route.params.id))}},[_vm._v(" Volver ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }